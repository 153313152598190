* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html {
    font-family: 'Poppins', sans-serif;
    --gray-color: #aaa;
    --white-color: rgb(242, 242, 242);
    --black-color: #161616;
    --red-color: #921515;
    --green-color: #4CAF50;
    --icon-color: #4CAF50;
}


.red {
    color: var(--red-color);
}
